import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from "../service/useScript"
import { Link } from "gatsby"
import toast from "react-hot-toast"
const MyReportsUpload = () => {
const metaTags = []
const handleSubmit = () => {
toast.success("Form submitted successfully")
}
useScript("/assets/js/custom_select.js")
useScript("/assets/js/uploadReport.js")
return (
<>
<Layout>
    <Meta
    files={{
    js: [],
    css: ["/assets/css/blog.css", "/assets/css/login.css", "/assets/css/accessibility.css"],
    }}
    tags={metaTags}
    />
    <main className="innerpage">
        <section className="section-bg section-py">
            <div className="container">
                <div className="arrowhead">
                    <Link to="/my-reports" className="back_arrow">
                    <img src="/assets/images/icons/left-arrow-event.svg" alt="" />			
                    </Link>
                    <h2 className="section-heading">John Doe</h2>
                </div>
                <div className="white_rounded_box">
                    <div className="report_upload_section">
                        <div className="form_wrap" id="report_upload_wrap">
                            <div className="form_block pl-0">
                                <p>To update your profile, kindly insert the requested details.</p>
                                <div className="row">
                                    <div className="col-md-2 col-3">
                                        <div className="form-group select-dd">
                                            <label>Title <span className="mandatory">*</span></label>
                                            <select name="" id="" className="form-control custom-select" placeholder="Mr.">
                                                <option value="">Mr.</option>
                                                <option value="">Miss.</option>
                                                <option value="">Dr.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-9">
                                        <div className="form-group -animated -active">
                                            <label>Full Name <span className="mandatory">*</span></label>
                                            <input className="form-control" type="text" value="Krishna Chandaluri" /> 
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group select-dd">
                                            <label>Date of Birth <span className="mandatory">*</span></label>
                                            <div className="row">
                                                <div className="col-2">
                                                    <select name="" id="dob_dd" className="form-control custom-select" placeholder="Date">
                                                        <option value="">Date</option>
                                                        <option value="1" selected>1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <select name="" id="dob_mm" className="form-control custom-select" placeholder="Month">
                                                        <option value="">Month</option>
                                                        <option name="January" value="Jan">01</option>
                                                        <option name="February" value="Feb" selected>02</option>
                                                        <option name="March" value="Mar">03</option>
                                                        <option name="April" value="Apr">04</option>
                                                        <option name="May" value="May">05</option>
                                                        <option name="June" value="Jun">06</option>
                                                        <option name="July" value="Jul">07</option>
                                                        <option name="August" value="Aug">08</option>
                                                        <option name="September" value="Sep">09</option>
                                                        <option name="October" value="Oct">10</option>
                                                        <option name="November" value="Nov">11</option>
                                                        <option name="December" value="Dec">12</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <select name="" id="dob_yy" className="form-control custom-select" placeholder="Year">
                                                        <option value="">Year</option>
                                                        <option value="2018">2018</option>
                                                        <option value="2017">2017</option>
                                                        <option value="2016">2016</option>
                                                        <option value="2015">2015</option>
                                                        <option value="2014">2014</option>
                                                        <option value="2013">2013</option>
                                                        <option value="2012">2012</option>
                                                        <option value="2011">2011</option>
                                                        <option value="2010">2010</option>
                                                        <option value="2009">2009</option>
                                                        <option value="2008">2008</option>
                                                        <option value="2007">2007</option>
                                                        <option value="2006">2006</option>
                                                        <option value="2005">2005</option>
                                                        <option value="2004">2004</option>
                                                        <option value="2003">2003</option>
                                                        <option value="2002">2002</option>
                                                        <option value="2001">2001</option>
                                                        <option value="2000">2000</option>
                                                        <option value="1999">1999</option>
                                                        <option value="1998">1998</option>
                                                        <option value="1997">1997</option>
                                                        <option value="1996">1996</option>
                                                        <option value="1995">1995</option>
                                                        <option value="1994">1994</option>
                                                        <option value="1993">1993</option>
                                                        <option value="1992">1992</option>
                                                        <option value="1991">1991</option>
                                                        <option value="1990">1990</option>
                                                        <option value="1989">1989</option>
                                                        <option value="1988">1988</option>
                                                        <option value="1987">1987</option>
                                                        <option value="1986">1986</option>
                                                        <option value="1985">1985</option>
                                                        <option value="1984">1984</option>
                                                        <option value="1983">1983</option>
                                                        <option value="1982">1982</option>
                                                        <option value="1981">1981</option>
                                                        <option value="1980" selected>1980</option>
                                                        <option value="1979">1979</option>
                                                        <option value="1978">1978</option>
                                                        <option value="1977">1977</option>
                                                        <option value="1976">1976</option>
                                                        <option value="1975">1975</option>
                                                        <option value="1974">1974</option>
                                                        <option value="1973">1973</option>
                                                        <option value="1972">1972</option>
                                                        <option value="1971">1971</option>
                                                        <option value="1970">1970</option>
                                                        <option value="1969">1969</option>
                                                        <option value="1968">1968</option>
                                                        <option value="1967">1967</option>
                                                        <option value="1966">1966</option>
                                                        <option value="1965">1965</option>
                                                        <option value="1964">1964</option>
                                                        <option value="1963">1963</option>
                                                        <option value="1962">1962</option>
                                                        <option value="1961">1961</option>
                                                        <option value="1960">1960</option>
                                                        <option value="1959">1959</option>
                                                        <option value="1958">1958</option>
                                                        <option value="1957">1957</option>
                                                        <option value="1956">1956</option>
                                                        <option value="1955">1955</option>
                                                        <option value="1954">1954</option>
                                                        <option value="1953">1953</option>
                                                        <option value="1952">1952</option>
                                                        <option value="1951">1951</option>
                                                        <option value="1950">1950</option>
                                                        <option value="1949">1949</option>
                                                        <option value="1948">1948</option>
                                                        <option value="1947">1947</option>
                                                        <option value="1946">1946</option>
                                                        <option value="1945">1945</option>
                                                        <option value="1944">1944</option>
                                                        <option value="1943">1943</option>
                                                        <option value="1942">1942</option>
                                                        <option value="1941">1941</option>
                                                        <option value="1940">1940</option>
                                                        <option value="1939">1939</option>
                                                        <option value="1938">1938</option>
                                                        <option value="1937">1937</option>
                                                        <option value="1936">1936</option>
                                                        <option value="1935">1935</option>
                                                        <option value="1934">1934</option>
                                                        <option value="1933">1933</option>
                                                        <option value="1932">1932</option>
                                                        <option value="1931">1931</option>
                                                        <option value="1930">1930</option>
                                                        <option value="1929">1929</option>
                                                        <option value="1928">1928</option>
                                                        <option value="1927">1927</option>
                                                        <option value="1926">1926</option>
                                                        <option value="1925">1925</option>
                                                        <option value="1924">1924</option>
                                                        <option value="1923">1923</option>
                                                        <option value="1922">1922</option>
                                                        <option value="1921">1921</option>
                                                        <option value="1920">1920</option>
                                                        <option value="1919">1919</option>
                                                        <option value="1918">1918</option>
                                                        <option value="1917">1917</option>
                                                        <option value="1916">1916</option>
                                                        <option value="1915">1915</option>
                                                        <option value="1914">1914</option>
                                                        <option value="1913">1913</option>
                                                        <option value="1912">1912</option>
                                                        <option value="1911">1911</option>
                                                        <option value="1910">1910</option>
                                                        <option value="1909">1909</option>
                                                        <option value="1908">1908</option>
                                                        <option value="1907">1907</option>
                                                        <option value="1906">1906</option>
                                                        <option value="1905">1905</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group select-dd">
                                            <label>Type of Report <span className="mandatory">*</span></label>
                                            <select name="" id="" className="form-control custom-select" placeholder="Report">
                                                <option value="">Report</option>
                                                <option value="">Prescriptions</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group upload-file -animated">
                                            <label>Upload Report </label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" readonly />
                                                <div className="input-group-btn"> <span className="fileUpload">
                                                    <span className="upl" id="upload">Upload </span>
                                                    <input type="file" className="upload up" id="up" /> </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="notes">
                                            <ul>
                                                <li>Make sure that:</li>
                                                <li>1. The image is not blurry.</li>
                                                <li>2. All the details are clearly visible.</li>
                                                <li>3. Image should not exceed 2 MB.</li>
                                                <li>4. Only .jpg .png format permitted.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <button className="btn btn-primary btn-continue" onClick={handleSubmit}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tankyou_div" style={{ display: "none" }}>
                        <div className="check_mark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                <g id="Group_2657" data-name="Group 2657" transform="translate(0)">
                                    <path id="Path_3040" data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470" />
                                    <g id="Group_2463" data-name="Group 2463" transform="translate(14.719 18.535)">
                                        <line id="Line_241" data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
                                        <line id="Line_242" data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="thankyou_content">
                            <p>Thank you <span>Krishna Chandaluri</span> for sharing your contact details.</p>
                            <p>Your HH number is 23234321.</p>
                        </div>
                        <div className="btn_wrap">
                            <button className="btn btn-primary">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </main>
</Layout>
</>
)
}
export default MyReportsUpload